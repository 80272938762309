import { render, staticRenderFns } from "./TermEdit.vue?vue&type=template&id=4dce63f6&scoped=true&"
import script from "./TermEdit.vue?vue&type=script&lang=js&"
export * from "./TermEdit.vue?vue&type=script&lang=js&"
import style0 from "./TermEdit.vue?vue&type=style&index=0&id=4dce63f6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dce63f6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2164834464/src/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4dce63f6')) {
      api.createRecord('4dce63f6', component.options)
    } else {
      api.reload('4dce63f6', component.options)
    }
    module.hot.accept("./TermEdit.vue?vue&type=template&id=4dce63f6&scoped=true&", function () {
      api.rerender('4dce63f6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/TermManagement/TermEdit.vue"
export default component.exports