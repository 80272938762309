import { render, staticRenderFns } from "./SchemaManagement.vue?vue&type=template&id=e92e3162&scoped=true&"
import script from "./SchemaManagement.vue?vue&type=script&lang=js&"
export * from "./SchemaManagement.vue?vue&type=script&lang=js&"
import style0 from "./SchemaManagement.vue?vue&type=style&index=0&id=e92e3162&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e92e3162",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2551249429/src/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e92e3162')) {
      api.createRecord('e92e3162', component.options)
    } else {
      api.reload('e92e3162', component.options)
    }
    module.hot.accept("./SchemaManagement.vue?vue&type=template&id=e92e3162&scoped=true&", function () {
      api.rerender('e92e3162', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/SchemaManagement/SchemaManagement.vue"
export default component.exports